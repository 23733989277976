<template>
  <div class="wrapper">
    <HomeView />
    <AboutView />
  </div>
</template>

<script>
import HomeView from "./Home.vue";
import AboutView from "./About.vue";

export default {
  name: "MainPage",
  components: {
    HomeView,
    AboutView,
  },
};
</script>
