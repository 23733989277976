<template>
  <div id="about" class="min-page">
    <div id="bg-image" class="min-page"></div>
    <div id="wrapper">
      <div id="left" data-aos="slide-right" data-aos-duration="1500">
        <div>
          <div id="title" class="font-rothek title">whoami</div>
          <div id="description" class="font-medium light-text">
            My name is <span class="white-text">Artuur</span> Janssens, I'm a
            <span class="white-text">senior freelance security consultant</span>
            based in Antwerp, Belgium. I have worked with many well known
            companies to strenghten their security and network.
            <br />
            I specialise in
            <span class="white-text">
              firewalling, network, cloud, VPN, SDWAN</span
            >
            and
            <span class="white-text">secure WFH. </span>
            I'm very passionate about this field and find myself playing around
            with new technologies whenever I can find the time.
          </div>
          <div>
            <div id="certifications" class="subtitle font-rothek">
              certifications
            </div>
            <div id="list" class="font-regular light-text">
              <div class="cert-block">
                <div class="company font-bold white-text">
                  Palo Alto Networks
                </div>
                <div class="cert-description font-medium">
                  <span>Certified Network Security Engineer (PCNSE)</span>
                </div>
              </div>
              <div class="cert-block">
                <div class="company font-bold white-text">
                  Check Point Software Technologies
                </div>
                <div class="cert-description font-medium">
                  <span>Certified Security Administrator (CCSA)</span>
                </div>
                <div class="cert-description font-medium">
                  <span>Certified Security Expert CCSE R80</span>
                </div>
                <div class="cert-description font-medium">
                  <span>Certified CloudGuard IaaS Administrator</span>
                </div>
              </div>
              <div class="cert-block">
                <div class="company font-bold white-text">OneSpan</div>
                <div class="cert-description font-medium">
                  <span>OCE for Authentication Server</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          <div id="cta" class="font-rothek">
            <div>
              Your employees work from home and you wonder what that might mean
              for your security?
            </div>
            <div id="cta-contact">
              <span
                ><a href="mailto:janssens@artuur.io?subject=Let's get in touch"
                  >Get in touch</a
                ></span
              >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="#ffffff"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          <div class="footer font-light">
            © 2023. Built with VueJS by
            <u
              ><a href="https://axellejamous.github.io/website/" target="_blank"
                >AJ</a
              ></u
            >. BE 0770.649.756.
          </div>
        </div>
      </div>
      <div id="right" data-aos="slide-left" data-aos-duration="1500">
        <div>
          <div class="font-rothek title">projects</div>
          <div id="project-wrapper">
            <div class="project-block">
              <div class="right">
                <div class="project font-medium">Orange Cyberdefense</div>
                <div class="text">
                  Offer consultancy for a wide range of customers in the SME
                  segment to design and maintain high performance on-prem
                  firewall deployments, specifically from
                  <span class="white-text">Palo Alto Networks</span>, as well as
                  help customers secure applications through
                  <span class="white-text">GlobalProtect</span>.
                </div>
                <div class="tags font-medium">
                  <div>Firewall</div>
                  <div>Palo Alto Networks</div>
                  <div>VPN</div>
                  <div>WFH</div>
                </div>
              </div>
            </div>
            <div class="project-block">
              <div class="right">
                <div class="project font-medium">Cocom</div>
                <div class="text">
                  Help startup Cocom secure their
                  <span class="white-text">cloud</span> development environment
                  in <span class="white-text">AWS</span> by creating pipelines
                  for developers to securely deploy code to their EC2 instances
                  and protect them against threats.
                </div>
                <div class="tags font-medium">
                  <div>Cloud</div>
                  <div>CI/CD</div>
                  <div>SecDevOps</div>
                  <div>Startup</div>
                </div>
              </div>
            </div>
            <div class="project-block">
              <div class="right">
                <div class="project font-medium">Honda Motor Europe</div>
                <div class="text">
                  Implement and maintain
                  <span class="white-text">Aruba Clearpass</span>
                  functionalities such as 802.1X, and manage a wide deployment
                  of <span class="white-text">Fortigate</span> firewalls all
                  across Europe. Deploy and strategize SSL certificate
                  deployments. Enable users to securely work from home with
                  <span class="white-text">Pulse Connect Secure</span>.
                </div>
                <div class="tags font-medium">
                  <div>NAC</div>
                  <div>Firewall</div>
                  <div>HTTPS</div>
                  <div>PKI</div>
                  <div>VPN</div>
                  <div>WFH</div>
                </div>
              </div>
            </div>
            <div class="project-block">
              <div class="right">
                <div class="project font-medium">Securitas</div>
                <div class="text">
                  Offer consultancy services for government institutions to
                  build and maintain their network security using
                  <span class="white-text">Check Point</span>,
                  <span class="white-text">Barracuda</span> firewalls and
                  <span class="white-text">Palo Alto Networks</span> virtual
                  firewalls, as well as design and implement large WiFi
                  deployments using <span class="white-text">Aruba</span>.
                </div>
                <div class="tags font-medium">
                  <div>Firewall</div>
                  <div>NAC</div>
                  <div>WiFi</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="footer">
          © 2023. Built with VueJS by
          <u
            ><a href="https://axellejamous.github.io/website/" target="_blank"
              >AJ</a
            ></u
          >. BE 0770.649.756.
        </div>
      </div>
    </div>
    <svg
      width="36px"
      height="36px"
      id="up-arrow"
      @click="scrollToTop()"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_429_11156)">
        <circle
          cx="12"
          cy="12"
          r="9"
          stroke="#ffffff"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 13L12 10L9 13"
          stroke="#ffffff"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_429_11156">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "AboutView",
  methods: {
    scrollToTop() {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
#about {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
}

#about #bg-image,
#about #wrapper {
  box-sizing: border-box;
  width: 100%;
  flex: none;
}

#about #bg-image {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../assets/bg5.jpg");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
}

#about #wrapper {
  min-height: 100%;
  display: flex;
  align-items: end;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  z-index: 2;
  margin-left: -100%;
}

#about #wrapper #left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 68%;
  width: 60%;
  padding: 64px 64px 18px 64px;
  background-color: #00050c;
  margin-right: -10%;
  z-index: 999;
}

#about #wrapper #right {
  min-height: 100%;
  width: 60%;
  padding: 64px;
  background-color: rgb(1, 11, 25);
}

#about #wrapper #left #description {
  padding-left: 14px;
  border-image: linear-gradient(#003459, #5eead4) 10;
  border-width: 4px;
  border-style: solid;
  border-right: 0;
  border-bottom: 0;
  border-top: 0;
  line-height: 1.3;
}

#about #wrapper #left #certifications {
  margin: 24px 0px 12px 0px;
}

#about #wrapper #left #list .cert-block {
  color: rgb(148, 163, 184);
  margin-bottom: 14px;
  font-size: 0.875rem;
  line-height: 1.3rem;
}

#about #wrapper #left #list .cert-block .cert-description {
  display: flex;
  align-items: center;
  column-gap: 4px;
  position: relative;
  padding-left: 20px;
}
#about #wrapper #left #list .cert-block .cert-description:hover {
  margin-left: 20px;
}
#about #wrapper #left #list .cert-block .cert-description::before {
  content: "▹";
  position: absolute;
  left: 0px;
  color: #5eead4; /*#64ffda;*/
  font-size: 14px;
  line-height: 12px;
}
#about #wrapper #left #cta {
  font-size: 20px;
  margin-bottom: 48px;
}

#about #wrapper #left #cta:hover {
  cursor: pointer;
}

#about #wrapper #left #cta #cta-contact {
  display: flex;
  align-items: center;
  margin-top: 14px;
}

#about #wrapper #left #cta #cta-contact span {
  border-bottom: 1px solid #5eead4;
}

#about #wrapper #left #cta #cta-contact svg {
  display: inline-block;
  margin-left: 0.25rem;
  width: 1rem;
  height: 1rem;
}

#about #wrapper #left #cta #cta-contact:hover svg {
  transform: translate(35px, 0);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

#about #wrapper #left #cta #cta-contact:hover span {
  color: #5eead4;
}

#about #wrapper #left #cta #cta-contact span a:hover {
  text-decoration: unset;
}

#about #wrapper #left #footer .footer {
  font-size: 12px;
  opacity: 0.6;
}

#about #wrapper #right #project-wrapper {
  padding-left: 16%;
  margin-bottom: 45px;
}

#about #wrapper #right .project-block {
  display: flex;
  margin-bottom: 14px;
  column-gap: 14px;
  border: 1px solid rgb(1, 11, 25);
  border-radius: 6px;
}

#about #wrapper #right .project-block:hover {
  background-color: rgba(30, 41, 59, 0.5);
}

#about #wrapper #right .project-block:hover .project {
  color: rgb(94, 234, 212) !important;
}

#about #wrapper #right .project-block .right {
  padding: 24px;
}

#about #wrapper #right .project-block .right .project {
  color: rgb(226, 232, 240);
}

#about #wrapper #right .project-block .right .text {
  font-size: 0.875rem;
  line-height: 1.5;
  margin-top: 0.5rem;
  color: rgb(148, 163, 184);
}

#about #wrapper #right .project-block .right .tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  column-gap: 0.375rem;
}

#about #wrapper #right .project-block .right .tags div {
  margin-top: 0.5rem;
  display: inline-block;
  color: rgb(94, 234, 212);
  line-height: 1.25rem;
  font-size: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background-color: rgba(45, 212, 191, 0.1);
  border-radius: 9999px;
}

#about #wrapper #right #footer {
  display: none;
}

#about #up-arrow {
  position: absolute;
  right: 18px;
  bottom: 18px;
  z-index: 9999;
}

#about #up-arrow:hover {
  cursor: pointer;
}

@media only screen and (max-width: 896px) {
  #about #wrapper {
    display: block;
  }

  #about #wrapper #left,
  #about #wrapper #right {
    width: 100%;
    min-height: 100vh;
  }

  #about #wrapper #left {
    padding: 32px;
  }

  #about #wrapper #right {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 18px 32px;
  }

  #about #wrapper #left #cta {
    font-size: 18px;
  }

  #about #wrapper #left #list .cert-block .cert-description {
    font-size: 14px;
  }

  #about #wrapper #right .project-block {
    background-color: rgba(30, 41, 59, 0.5);
  }

  #about #wrapper #left #cta {
    margin-bottom: 24px;
  }

  #about #wrapper #left #footer .footer {
    display: none;
  }

  #about #wrapper #right #project-wrapper {
    padding-left: unset;
  }

  #about #wrapper #right .project-block .project {
    color: rgb(94, 234, 212) !important;
  }

  #about #wrapper #right #footer {
    display: block;
    margin-top: auto;
    font-size: 11px;
    opacity: 0.6;
  }
}

@media only screen and (max-height: 670px) {
  #about #wrapper #left #description {
    font-size: 14px;
  }

  #about #wrapper #left #cta {
    font-size: 16px;
  }
}
</style>
