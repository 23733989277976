<template>
  <div id="contactBar">
    <div id="linkedIn">
      <a href="https://www.linkedin.com/in/artuurjanssens" target="_blank">
        <svg
          @mouseover="fillColor[0] = 'rgb(226, 232, 240)'"
          @mouseleave="fillColor[0] = '#ffffff'"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>LinkedIn</title>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.2857 0H1.70893C0.766071 0 0 0.776786 0 1.73036V22.2696C0 23.2232 0.766071 24 1.70893 24H22.2857C23.2286 24 24 23.2232 24 22.2696V1.73036C24 0.776786 23.2286 0 22.2857 0ZM7.25357 20.5714H3.69643V9.11786H7.25893V20.5714H7.25357ZM5.475 7.55357C4.33393 7.55357 3.4125 6.62679 3.4125 5.49107C3.4125 4.35536 4.33393 3.42857 5.475 3.42857C6.61071 3.42857 7.5375 4.35536 7.5375 5.49107C7.5375 6.63214 6.61607 7.55357 5.475 7.55357ZM20.5875 20.5714H17.0304V15C17.0304 13.6714 17.0036 11.9625 15.1821 11.9625C13.3286 11.9625 13.0446 13.4089 13.0446 14.9036V20.5714H9.4875V9.11786H12.9V10.6821H12.9482C13.425 9.78214 14.5875 8.83393 16.3179 8.83393C19.9179 8.83393 20.5875 11.2071 20.5875 14.2929V20.5714Z"
            :fill="fillColor[0]"
          />
        </svg>
      </a>
    </div>
    <div>
      <svg
        width="28px"
        height="28px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Interface / Line_S">
          <path
            id="Vector"
            d="M12 15V9"
            stroke="#ffffff"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </div>
    <div id="mail">
      <a href="mailto:janssens@artuur.io?subject=Found your website">
        <svg
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          @mouseover="fillColor[1] = 'rgb(226, 232, 240)'"
          @mouseleave="fillColor[1] = '#ffffff'"
        >
          <title>E-mail me</title>
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-1200.000000, -48.000000)">
              <g transform="translate(1200.000000, 48.000000)">
                <path
                  d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                  fill-rule="nonzero"
                ></path>
                <path
                  d="M4.49998,12 C4.49998,7.85786 7.85784,4.5 12,4.5 C17.0506,4.5 19.9167,8.66698 19.5092,12.3343 C19.3971,13.3426 19.108,13.8915 18.8873,14.1835 C18.6266,14.5284 18.1554,14.8542 17.703,14.838 C17.3723,14.8262 17.4712,14.3628 17.4925,14.1493 L17.9926,9.14926 C18.075,8.32495 17.4736,7.58988 16.6493,7.50744 C16.2585,7.46837 15.8879,7.58294 15.5972,7.80208 C14.6374,6.99382 13.3961,6.5 12.0305,6.5 C9.00785,6.5 6.49998,8.94763 6.49998,12 C6.49998,15.0558 9.01843,17.5 12.0377,17.5 C13.167,17.5 14.2392,17.1554 15.1338,16.5541 C15.2968,16.7752 15.491,16.9787 15.7176,17.1581 C16.8264,18.0361 18.3002,18.0269 19.6707,17.3416 C20.0525,17.1508 20.6953,16.7667 21.2805,15.9925 C21.8658,15.2182 22.3267,14.1421 22.4908,12.6657 C23.0834,7.33302 18.9493,1.5 12,1.5 C6.20099,1.5 1.49998,6.20101 1.49998,12 C1.49998,17.799 6.20099,22.5 12,22.5 C13.6736,22.5 15.2598,22.1073 16.6675,21.4078 C17.4094,21.0391 17.7119,20.1388 17.3433,19.3969 C16.9746,18.6551 16.0743,18.3525 15.3324,18.7212 C14.3303,19.2192 13.2001,19.5 12,19.5 C7.85784,19.5 4.49998,16.1421 4.49998,12 Z M9.49998,12 C9.49998,10.6341 10.6349,9.5 12.0305,9.5 C13.639,9.5 14.8381,10.9814 14.5144,12.516 C14.2733,13.6589 13.2333,14.5 12.0377,14.5 C10.6386,14.5 9.49998,13.3625 9.49998,12 Z"
                  :fill="fillColor[1]"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactBarComponent",
  data() {
    return {
      fillColor: ["#ffffff", "#ffffff"],
    };
  },
};
</script>

<style scoped>
#contactBar {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;
  margin-bottom: 64px;
}
#contactBar svg {
  height: 28px;
  width: 28px;
}
#contactBar svg:hover {
  cursor: pointer;
}

@media only screen and (max-width: 896px) {
  #contactBar {
    margin-bottom: 42px;
  }
}
@media only screen and (max-height: 850px) and (orientation: landscape) {
  #contactBar {
    margin-bottom: 4px;
  }
  #contactBar svg {
    height: 24px;
    width: 24px;
  }
}
@media only screen and (max-height: 570) and (orientation: portrait) {
  #contactBar {
    margin-bottom: 20px;
  }
  #contactBar svg {
    height: 24px;
    width: 24px;
  }
}
@media only screen and (max-height: 550px) and (orientation: portrait) {
  #contactBar {
    margin-bottom: 16px;
  }
}
/* galaxy fold */
@media only screen and (max-height: 300px) and (orientation: landscape) {
  #contactBar svg {
    height: 18px;
    width: 18px;
  }
}
</style>
