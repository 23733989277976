<template>
  <div id="home" class="page">
    <div class="centered-content">
      <div id="top-logo" class="font-rothek">aio</div>
      <div id="big-logo">
        <LogoComponent></LogoComponent>
        <div id="text" class="font-rothek">
          <span>security consulting</span>
        </div>
      </div>
    </div>
    <div id="footer">
      <div>
        <ContactBarComponent />
      </div>
      <div id="footer-centered">
        <div id="scroll-down" @click="scrollDown()">
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 10L12 15L17 10"
              stroke="#ffffff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoComponent from "../components/Logo.vue";
import ContactBarComponent from "../components/ContactBar.vue";

export default {
  name: "HomeView",
  components: {
    LogoComponent,
    ContactBarComponent,
  },
  methods: {
    scrollDown() {
      var element = document.getElementById("about");
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
#home {
  background-color: black;
  color: white;
}
#home #top-logo {
  position: absolute;
  top: 18px;
  right: 18px;
  letter-spacing: 0.3rem;
  text-indent: 0.3rem;
  font-size: 28px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-top: 3px solid;
  border-bottom: 3px solid;
}
#home #big-logo {
  width: 650px;
  position: relative;
}
#home .centered-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#home #text {
  position: absolute;
  width: 100%;
  bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  font-size: 26px;
  letter-spacing: 0.25rem;
  text-indent: 0.25rem;
}
#home #footer {
  width: 100%;
  position: absolute;
  bottom: 0;
}
#home #footer #footer-centered {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
}
#home #footer #footer-centered #scroll-down {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
#home #footer #footer-centered #scroll-down svg {
  width: 45px;
  height: 45px;
}
#home #footer #footer-centered #scroll-down svg:hover {
  cursor: pointer;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-25px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* small screens */
@media only screen and (min-width: 896px) and (max-height: 550px) {
  #home #big-logo {
    width: 300px;
  }
  #home #text {
    font-size: 16px;
    bottom: 16px;
  }
}
/*also capture media such as Galaxy s20 Ultra */
@media only screen and (max-width: 920px) {
  #home #text {
    font-size: 16px;
    bottom: 16px;
  }
}
@media only screen and (max-width: 896px) {
  #home #top-logo {
    font-size: 18px;
  }
  #home #big-logo {
    width: 380px;
  }
  #home #footer #footer-centered #scroll-down svg {
    width: 35px;
    height: 35px;
  }
}
@media only screen and (max-height: 550px) and (orientation: portrait) {
  #home #big-logo {
    width: 300px;
  }
  #home #text {
    bottom: 12px;
  }
}
@media only screen and (max-height: 450px) and (orientation: landscape) {
  #home #big-logo {
    width: 300px;
  }
  #home #text {
    bottom: 12px;
  }
}
@media only screen and (max-height: 370px) and (orientation: landscape) {
  #home .centered-content {
    justify-content: flex-start;
    padding-top: 28px;
  }
}
/* galaxy fold */
@media only screen and (max-width: 300px) {
  #home #big-logo {
    width: 340px;
  }
}
@media only screen and (max-height: 300px) and (orientation: landscape) {
  #home #big-logo {
    width: 220px;
  }
  #home #text {
    font-size: 12px;
    bottom: 8px;
  }
  #home #footer #footer-centered #scroll-down svg {
    width: 25px;
    height: 25px;
  }
}
</style>
