<template>
  <MainPage />
</template>

<script>
import MainPage from "./views/MainPage.vue";

export default {
  name: "App",
  components: {
    MainPage,
  },
};
</script>

<style>
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(../public/fonts/Roboto/Roboto-Black.ttf);
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "RobotoThin";
  src: local("RobotoThin"), url(../public/fonts/Roboto/Roboto-Thin.ttf);
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "RobotoLight";
  src: local("RobotoLight"), url(../public/fonts/Roboto/Roboto-Light.ttf);
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"), url(../public/fonts/Roboto/Roboto-Regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "RobotoMedium";
  src: local("RobotoMedium"), url(../public/fonts/Roboto/Roboto-Medium.ttf);
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"), url(../public/fonts/Roboto/Roboto-Bold.ttf);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "RothekBold";
  src: local("RothekBold"), url(../public/fonts/Rothek/RothekBold.otf);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

*:before,
*:after {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: unset;
  color: unset;
}
a:hover {
  text-decoration: underline;
}

.font-light {
  font-family: "RobotoLight";
}
.font-regular {
  font-family: "RobotoRegular";
}
.font-medium {
  font-family: "RobotoMedium";
}
.font-bold {
  font-family: "RobotoBold";
}
.font-rothek {
  font-family: "RothekBold";
}

.title {
  font-size: 48px;
  margin-bottom: 36px;
}
.subtitle {
  font-size: 28px;
  color: rgb(148, 190, 184);
}
.light-text {
  color: rgb(148, 163, 184, 1);
}
.white-text {
  color: rgba(226, 232, 240, 1);
}
.white-text:hover {
  color: #5eead4;
}

.page {
  height: 100vh;
}
.min-page {
  min-height: 100vh;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

@media only screen and (max-width: 896px) {
  .title {
    font-size: 42px;
  }
}
@media only screen and (max-height: 670px) {
  .title {
    font-size: 36px;
    margin-bottom: 24px;
  }
  .subtitle {
    font-size: 24px;
  }
}
</style>
